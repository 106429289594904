<template>
    <!-- Component: organisms/login-form -->
    <div v-if="!userLoading">
        <form action="#sign-in" v-on:submit.prevent="login" v-if="!showForgot">

            <div class="field">
                <label class="label" for="login">Username or Email</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Username/Email" name="login" id="login" v-model="loginParams.username">
                </div>
            </div>

            <div class="field">
                <label class="label" for="password">Password</label>
                <div class="control">
                    <input class="input" placeholder="Password" type="password" name="password" id="password" v-model="loginParams.password">
                </div>
                <a href="#reset-password" class="reset-password"
                   @click.prevent="showForgot = true"
                >Forgot password?</a>
            </div>

            <div>
                <input type="submit" class="button is-primary" value="Sign In">
            </div>
            <div v-if="signInError" class="error-message">Unable to sign in, please check credentials and try again.
            </div>
        </form>
        <form action="#forgot" v-on:submit.prevent="forgotPassword()" v-else-if="!loading">
            <div v-if="!resetSuccess">
                <div class="field">
                    <label class="label" for="fp-email">Username or Email</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="Username/Email"
                               required
                               name="fp-email" id="fp-email" v-model="loginParams.username">
                    </div>
                </div>

                <div>
                    <input type="submit" class="button is-primary" value="Reset">
                </div>
            </div>
            <div v-else>
                <p>We have sent an email to the email address of your account. You should receive the email shortly.</p>
                <p>If the email is slow to arrive, check your spam folders.</p>
                <p>If you receive no email, it's that your username or email is unknown to us, so please double check.</p>
                <p>If you reset your password multiple times, only the most recent email link will be valid</p>
            </div>
            <div v-if="resetError" class="error-message" v-html="resetError"></div>
        </form>
        <preloader v-if="loading"/>

        <br>
        <div v-if="showForgot" class="back-login">
            <a href="#login" @click.prevent="showForgot = resetSuccess = false">&lt; Sign In</a>
        </div>
    </div>
    <preloader v-else></preloader>
    <!-- End Component: organisms/login-form -->
</template>

<script>
    import Preloader from "./preloader";
    import { useStore } from "vuex"
    import { computed, reactive, ref, watch } from 'vue'
    import { useRouter } from "vue-router";
    import { ACTION_TYPES } from "@/constants";
    import useUser from "@/helpers/useUser";
    import RequestHandler from "@/handler/RequestHandler";
    import {MUTATION_TYPES} from "@/constants";

    export default {
        name: 'user-login',
        components: { Preloader },
        setup() {
            const store = useStore();
            const signInError = computed(() => store.getters.isSignInError);
            const loading = ref(false);
            const showForgot = ref(false);
            const resetSuccess = ref(false);
            const resetError = ref('');

            const { redirectIfLoggedIn, userLoaded, userLoading } = useUser();
            const router = useRouter();
            watch(userLoaded, (toLoaded) => {
                if (toLoaded) {
                    redirectIfLoggedIn(router);
                }
            });

            // 'data' query param
            const data = router.currentRoute.value.query.data;
            if (data) {
                RequestHandler.decryptData(data, store.getters.csrf).then((data) => {
                    if (data) {
                        let username = data.username;
                        let password = data.password;
                        if (username && password) {
                            store.commit(MUTATION_TYPES.setSavedPasswordFromData, password);
                            loginParams.username = username;
                            loginParams.password = password;
                            login();
                        }
                    }
                });
            }

            const loginParams = reactive({
                username: null,
                password: null
            });

            const login = () => {
                store.dispatch(ACTION_TYPES.signIn, {
                    login: loginParams.username,
                    password: loginParams.password,
                });
            }

            const forgotPassword = () => {
                resetError.value = '';
                loading.value = true;
                RequestHandler.forgotPassword(loginParams.username, store.getters.csrf).then(() => {
                    resetSuccess.value = true;
                    loading.value = false;
                }).catch((error) => {
                    loading.value = false;
                    resetError.value = (error.response.data && error.response.data.error)
                        ? error.response.data.error.replaceAll('ERROR:', '<br>●')
                        : 'Something went wrong, please try again';
                });
            }
            return {
                userLoading,
                login,
                loginParams,
                signInError,
                loading,
                showForgot,
                forgotPassword,
                resetSuccess,
                resetError
            };
        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";

    .error-message {
        margin-top: 20px;
        font-style: italic;
        color: $red;
    }

    .input {
        border: 2px solid $blue--dark;
    }

    .button {
        margin-top: 10px;
    }

    .back-login {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .reset-password {
        margin-top: 4px;
        display: block;
        text-align: right;
    }
    .control {
        display: flex;
        i {
            margin-left: -30px;
            cursor: pointer;
            z-index: 2;
            position: relative;
            top: 11px;
            display: inline-block;
            width: 25px;
            height: 25px;
        }
    }
</style>
