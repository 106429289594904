<template>
    <!-- Page: pages/login -->
    <div class="container">
        <div class="login-container create-container">
            <span>New to GovGuide?</span>
            <router-link to="/register" class="button is-primary"> Create New Account</router-link>
        </div>

        <div class="login-container">
            <h1 class="title">Sign In</h1>
            <user-login></user-login>
        </div>
    </div>
    <teaser-page></teaser-page>
    <!-- End Page: pages/login -->
</template>

<script>
    import UserLogin from "@/alivue/components/login-form";
    import TeaserPage from "@/alivue/components/teaser";

    export default {
        name: 'login-page',
        components: {
            UserLogin,
            TeaserPage
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    h1 {
        margin-bottom: 30px;
    }
    .login-container {
        max-width: 600px;
        margin: 0 auto;
        margin-top: 50px;
        @media screen and (max-width: 650px) {
            margin: 0 15px;
        }
    }
    .title {
        margin-top: 30px;
    }
    .create-container {
        background-color: rgba(51,116,170,.1);
        border: 1px solid #3374aa;
        padding: 15px;
        text-align: center;
        @media screen and (max-width: 650px) {
            margin-top: 50px;
        }
        span {
            position: relative;
            top: 8px;
            padding-right: 15px;
            font-size: 18px;
            font-weight: bold;
        }
        @media screen and (max-width: 500px) {
            span {
                display: block;
                width: 100%;
                padding-right: 0px !important;
                margin-bottom: 10px;
                top: 0px;
            }
        }
    }
</style>
